import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CYCLE_DISPLAY_PART, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { cycleDisplayPart } from '@@/_new_src_/store/myCyclesSlice';
import CycleStatusBar from '../CycleStatusBar';
import CycleExpectations from '../CycleExpectations';
import CycleInformation from '../CycleInformation';
import CycleHistoryList from '@@/features/performance/reviews/reviewList/CycleHistoryList';
import TabPaneComponent from '@@/_new_src_/components/TabPaneComponent';
import Tip from '../../../../components/Tip';
import { IMyCyclesLocale } from '../../../../types/myCycles';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { get } from 'lodash';
import { getMonthDay } from '@@/_new_src_/utils/common/date';
import './index.less';

const CycleInfoDisplayPage = () => {
  const {
    myCycles: { draftedTip, draftedTipForProbation },
  } = useSelector(selectLocaleResource) as IMyCyclesLocale;

  const currentPart = useSelector(cycleDisplayPart);

  const {
    currentCycleDetail: { isNewCycle, duration, isProbation, createBySystem },
  } = useSelector(cycleDetailByVersion);
  const { futureCycleVersion, hireDate } = useSelector(userInfo);

  const add30Days = (date: Date) => {
    date.setDate(date.getDate() + 30);
    return date;
  };

  const addTwoMonthsSubtractOneDay = (date: Date) => {
    date.setMonth(date.getMonth() + 2);
    date.setDate(date.getDate() - 1);
    return date;
  };

  const autoOpenDate = useMemo(() => {
    const startTime = get(duration, 'startTime', '');
    const isNewJoiner = hireDate === startTime;
    const startDate = new Date(startTime);
    // new joiner: startTime + 30D auto open
    // existing twer: startTime + 2M -1D auto open
    if (isNewJoiner) {
      return add30Days(startDate);
    } else {
      return addTwoMonthsSubtractOneDay(startDate);
    }
  }, [duration, hireDate]);

  return (
    <>
      {currentPart === CYCLE_DISPLAY_PART.CYCLE_INFO_DISPLAY && (
        <div className="cycle-info-display-page">
          <div className="cycle-expectation-content">
            <div className="status-bar">
              <CycleStatusBar />
            </div>
            {isNewCycle && (
              <>
                <TabPaneComponent
                  className="tabs-container"
                  tabList={['Detail']}
                  type={'card'}
                  tabBarGutter={9}
                  centered={true}
                  size={'large'}
                />
                {CYCLE_VERSION.NEW === futureCycleVersion && (
                  <Tip
                    wording={
                      !isProbation && createBySystem
                        ? draftedTip(getMonthDay(autoOpenDate))
                        : draftedTipForProbation
                    }
                    className={'orange-tip'}
                  />
                )}
              </>
            )}
            <div className="cycle-expectation">
              <CycleExpectations />
            </div>
            <div className="cycle-information">
              <CycleInformation />
            </div>
          </div>
          <div className="cycle-history">
            <CycleHistoryList />
          </div>
        </div>
      )}
    </>
  );
};

export default CycleInfoDisplayPage;
